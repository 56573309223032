/* Styles specifically for the Signup page */

/* Make the page take up full height */
.signup-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Keep header at top */
  height: 100vh;
  background-color: #5BDBB0; /* Red background for the entire page */
}
.signup-page-container h1{
  font-size: 56px;
  text-align: center;
  background-color: #5BDBB0;
  color: #ffe3e3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}
/* Header remains at the top */
header {
  /* Assuming the header styling is already defined in your Header component */
  /* Make sure header is positioned at the top of the page */
}

/* Content will be centered below the header */
.signup-content {
  background-color: white; /* White background for the content container */
  padding: 40px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  max-height: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 50px; /* Space between header and content */
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup-info p {
  font-size: 35px;
  line-height: 1.5;
}
/* Style the ball on the left */
.ball-left img,
.ball-right img {
  width: 250px; /* 50% of the original 80px width */
  height: 350px; /* 50% of the original 80px height */
  object-fit: cover; /* Ensure the image is not stretched */
}

/* Position the ball on the left */
.ball-left {
  position: absolute;
  right: 5%; /* Adjust horizontal position */
  top: 50%;   /* Lower than ball1 */
  transform: rotate(25deg); /* Different angle */
}

/* Position the ball on the right */
.ball-right {
  position: absolute;
  right: 5%; /* Adjust horizontal position */
  top: 40%;   /* Lower than ball1 */
  transform: rotate(25deg); /* Different angle */
}

.signup-info a {
  color: #007bff;
  text-decoration: none;
}

.signup-info a:hover {
  text-decoration: underline;
}

/* Styles for the handicap icon and zoom menu */
.handicap-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.zoom-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zoom-menu2 {
  position: absolute;
  top: 90%;
  left: 20px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zoom-menu2 button {
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.zoom-menu button {
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.zoom-menu button:hover {
  background-color: #45a049;
}
@media (max-width: 768px) {
  .signup-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Keep header at top */
    height: 130vh;
    background-color: #5BDBB0; /* Red background for the entire page */
  }
  .ball-left img,
.ball-right img {
  width: 150px; /* 50% of the original 80px width */
  height: 200px; /* 50% of the original 80px height */
  object-fit: cover; /* Ensure the image is not stretched */
}

/* Position the ball on the left */
.ball-left {
  position: absolute;
  right: 5%; /* Adjust horizontal position */
  top: 50%;   /* Lower than ball1 */
  transform: rotate(25deg); /* Different angle */
}

/* Position the ball on the right */
.ball-right {
  position: absolute;
  right: 5%; /* Adjust horizontal position */
  top: 40%;   /* Lower than ball1 */
  transform: rotate(25deg); /* Different angle */
}
}