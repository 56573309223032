

.main-container {
    max-width: 100%;
    
  }
  
  .content {
    background-color: #f9f9f9;
    
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 18px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 15px;
  }
  
  ul {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  li {
    font-size: 16px;
    color: #333;
  }
  .hero-section3{
    width: 100vw;
    height: 100vh;
     
   padding-bottom: 280px;
    display: flex;
    justify-content: center;
    
    background-color:#ED596B;

  }
.hero-section1 {
  width: 90vw;
  height: 100vh;
  background-image: url('../images/active.jpg'); 
  background-size: cover; 
  background-position: center; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#ED596B ;
  border-radius: 60px;
  
}
.hero-section2 {
  width: 100%;
  height: 50vh;
  background-image: url('../images/ymca1.webp'); 
  background-size: cover; 
  background-position: center; 
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.hero-text1 {
  background-color: #ED596B;
}
.hero-text1 h1 {
  font-size: 56px;
  text-align: center;
  background-color: #ED596B;
  color: #ffe3e3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

.hero-text h1 {
  font-size: 36px;
  color: #0f0e0e; 
}

.flex-container {
  
  justify-content: space-between;
  
}



.image-section {
  width: 99.1vw;
  height: 140vh;
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-image: url('../images/annan2.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #5AA9EB;
  
}

.text-section {
  background-color: #5AA9EB;
  padding-top: 500px;
  padding-bottom: 500px;
  
}
.connecting-image {
  width: 100vw; /* Adjust size as needed */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
 
}
.connecting-image2 {
  width: 100vw; /* Adjust size as needed */
  position: absolute;
  left: 50%;
  top: 155vh;
  transform: translateX(-50%);
  z-index: 1;
 
}
.connecting-image3 {
  width: 100vw; /* Adjust size as needed */
  position: absolute;
  left: 50%;
  top: 100vh;
  transform: translateX(-50%);
  z-index: 1;
 
}

.imported-image-container {
  align-self: start;
  text-align: center;
 
  
  height: 800px;
  background-color: #fff;
}

.imported-image {
  width: 700px; 
  height:auto;
  border-radius: 10px; 
}

.text-section p {
  font-size: 20px;
  line-height: 1.6;
  color: #ffffff;
  margin: 25px 100px; 
  text-align: left;
  
}

.text-section h1 {
  font-size: 25px;
  font-weight: bold; 
  color: #ffffff ;
  text-align: left;
}
.text-section li {
  font-size: 18px;
  
  color: #ffffff;
  text-align: left;
}
.text-section ul {
  font-size: 18px;
  
  color: #ffffff;
  text-align: left;
  list-style-position: inside; /* Moves bullets inside the list item */
  padding-left: 0; /* Ensures no extra left space */
}

.activities-title {
  text-align: center;
  margin-top: 40px;
  font-size: 28px; 
  font-weight: bold; 
  color: #394244;
}
/* dailySchedule.css */

.daily-schedule-container {
    
    margin: 0 auto;
    text-align:center;
  }
  
  .content23 {
  
    line-height: 1.6;
    text-align:center;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    text-align:center;
  }
  
  .classrooms-info {
    font-size: 18px;
    margin-bottom: 15px;
    text-align:center;
  }
  .classrooms-info ul {
    list-style: none; /* Remove default list styling */
    padding: 0;
    text-align: center; /* Center the list items */
  }
  
  .classrooms-info li {
    font-size: 18px;
    margin-bottom: 5px; /* Adjust as needed */
    
    margin-right: 10px; /* Add some spacing between list items */
  }
  .schedule-info {
    font-size: 18px;
    margin-bottom: 15px;
    text-align:center;
  }
  
  .august-info {
    font-size: 18px;
    font-weight: bold;
    text-align:center;
  }
  .activities-title2 {
    text-align: left;
    
    margin-top: 20px;
    font-size: 26px; 
    font-weight: bold; 
    color: #333;
   padding-left: 5%;
  }
  
  .handicap-logo2{
    margin-left: 90%;
    
  }
  .menu-container2{
    margin-left: 90%;
  }
  .menu-container2 label,
.menu-container2 button {
  display: block;
  margin-bottom: 5px;
}

.menu-container2 button {
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.menu-container2 button:hover {
  background-color: #555; /* Change color on hover */
}
.menu-container3 {
  position: absolute;
  top: 65%; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}
.menu-container3 label,
.menu-container3 button {
display: block;

}

.menu-container3 button {

background-color: #333;
color: white;
border: none;
cursor: pointer;
}

.menu-container3 button:hover {
background-color: #555; /* Change color on hover */
}
@media only screen and (max-width: 767px) {
  .connecting-image2 {
   display: none;
   
  }
  .connecting-image {
    display: none;
    
   }
  .image-section {
    width: 96.5vw;
    height: 140vh;
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-image: url('../images/tele5.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #5AA9EB;
    
  }
  .flex-container {
    flex-direction: column;
    margin-bottom: 70%;
    height: 50vh;
  }
  .connecting-image3 {
    display: none;
  }
  .hero-section3{
    width: 96.5vw;
    height:35vh;
     
   padding-bottom: 280px;
    display: flex;
    justify-content: center;
    
    background-color:#ED596B;

  }
  .hero-section1 {
    border-radius: 60px 60px 0 0;
    width: 90vw;
    height: 60vh;
    background-image: url('../images/tele2.png'); 
    background-size: cover; 
    background-position: center; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#ED596B ;
  }

  .text-section {
    background-color: #5AA9EB;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 96.5vw;
  }

  .image-section img {
    height: auto;
    
  }

  .imported-image-container {
    margin-top: 20px;
    height: auto;
    
  }

  .imported-image {
    width: 100%;
    height: auto;
    margin-bottom: 250px;
  }

  .activities-title {
    margin-top: 30px;
  }
}
