/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
  }
  
  .modal-content {
    background: white;
    width: 60vw; /* 60% of the viewport width */
    height: 80vh; /* 60% of the viewport height */
    text-align: center;
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  }
  
  .modal-photo {
    max-width: 100%; /* Ensures the photo takes up 100% of the modal content width */
  max-height: 100%; /* Ensures the photo fits within the modal content height */
  object-fit: contain; /* Maintains the aspect ratio of the image while filling the container */
  border-radius: 15px;
  margin: 0 20px;
    
  }
  
  .close-button {
    background: #d99494;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
  }


.nav-button {
    background: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    
    font-size: 16px;
  }
  
  .left {
    float: left;
    left: 10px;
  }
  
  .right {
    float: right;
    right: 10px;
  }
  