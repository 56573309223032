body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  
}
.content {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 background-color: rgb(8, 11, 11);
}
.video-container {
  
  width: 100vw;
  height: 75vh;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activitiees {
  position: relative;
  display: flex;
  justify-content: space-around;
  
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
}

.activitiees::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: 50%;
  background-position: center;
 
  z-index: 0; /* Ensure it stays behind the content */
}

.activitiees * {
  position: relative; /* Keeps content above the background */
  z-index: 1;
}





.activity {
  text-align: center;
  
}
.grey-rectangle {
  background-color: #333;
  color: rgb(94, 149, 0);
  padding: 20px;
  text-align: center;
  width: 100%;
}
.grey-rectangle p {
  color: #ffe8e8;
}

.activity h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: rgb(0, 0, 0);
}

.activity-images img {
  width: 350px;
  height: 350px;
  margin: 5px;
  object-fit: cover;
  border-radius: 5px;
  
  display: block;
  transition: all 0.3s ease; /* Add a smooth transition effect */
}

.activity-images img:hover {
  transform: scale(1.2); /* Scale the image slightly larger on hover */
}




.imported-image-container {
  text-align: center;
  margin-top: 20px;
  height: 800px;
  
}

.imported-image {
  width: 700px; /* Adjust the width as needed */
  height:auto;
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
  
}


.hero-section2 {
  width: 100%;
  height: 40vh;
  background-image: url('../images/aact9.jpg'); 
  background-size: cover; 
  background-position: center; 
  display: flex;
  justify-content: center;
  align-items: center;
 
  margin-left: 30px;
}

.hero-text2 h1 {
  font-size: 36px;
  color: #010101;
  text-shadow: 2px 2px 4px rgba(232, 226, 226, 0.7);
}





.info-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 40px;
  height: 500px;
  background-color: white;
}

.info-box {
  text-align: center;
  height: 80px;
  width: 200px;
  margin: 30px;
  margin-right: 60px;
}
.info-box2 {
  text-align: center;
  height: 100px;
  width: 200px;
  margin: 30px;
  margin-right: 60px;
}

.logo {
  width: 80px; 
  height: 80px;
  margin-bottom: 10px;
}
.zoom-menu2 {
  position: absolute;
  top: 80%;
  left: 40px;
  background-color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}
.zoom-menu2 button {
  margin: 5px 0;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.image-container {
  width: 99.1vw;
  height: 155vh;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
  position: relative;
  background-image: url('../images/annan1.png'); 
  background-color: #5BDBB0;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  z-index: 9;
}


.background-image {
  width: 30%;
  height: 100%;
  object-fit: cover;
  margin-right: 30px;
}
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.image-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 20vh;
  padding-top: 40px;
  background-color: #5BDBB0;
  
}
.image-text-container2  {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  z-index: 9999;
  background-color: #5BDBB0;
  
}

.image-text {
  font-size: 2.8em;
  
  
  color: #ffe3e3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  margin: -5px;  
}

.handicap-logo {
  margin-right: 10px; /* Adjust margin as needed */
  margin-left: 3%;
  margin-top: 7%;
}




.button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
/* main.css */
/* ... (existing styles) */
/* Add this CSS code to your main.css file or relevant CSS file */

.menu-container {
  position: absolute;
  top: 18%; /* Adjust the top position as needed */
  left: 15px; /* Adjust the left position as needed */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.menu-container label,
.menu-container button {
  display: block;
  margin-bottom: 5px;
}

.menu-container button {
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.menu-container button:hover {
  background-color: #555; /* Change color on hover */
}
.bold-text {
  font-weight: bold;
}

/* Responsive Styles for Small Screens */
@media only screen and (max-width: 768px) {
  .info-container {
    display: flex;
  flex-direction: column; /* Change flex direction to column on small screens */
  align-items: center; /* Center items horizontally on small screens */
  margin-top: 100px;
  margin-bottom: 400px;
  background-color: #ffffff;
  padding: 220px 30px;
  }
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
    background-color: #5BDBB0;

  }
  .info-box,
  .info-box2 {
    width: 80%; /* Adjust width for better responsiveness on small screens */
    height: 20%;
    margin: 50px;
  }
  .logo {
    
    max-width: 100%; /* Ensure logos don't exceed their container width */
  }
  .image-container {
    background-image: url('../images/tele4.png'); 
    padding-top: 0; /* Remove extra spacing */
    margin-top: 15vh;
    margin-bottom: 15vh;
    background-color: #5BDBB0;
  }

  .background-image {
    width: 100%; /* Make images take full width */
    height: auto; /* Adjust height dynamically */
    margin: 0; /* Remove spacing between images */
    margin-top: 5px;
  }
  
  

  
  .image-text-container {
    display: flex;
  align-items: center;
  flex-direction: column;
  height: 1vh;
  background-color: #5BDBB0;
  }

  .image-text {
    font-size: 1.9em; /* Adjust font size for better readability on small screens */
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
  }
  .handicap-logo {
    margin-right: 10px; /* Adjust margin as needed */
    margin-left: 3%;
    margin-top: 20%;
  }
  .activitiees {
    flex-direction: column; /* Change flex direction to column on small screens */
    align-items: center; /* Center items horizontally on small screens */
  }

  .activity {
    width: 100%; /* Set the width to 100% for single-column layout on small screens */
    margin-bottom: 20px; /* Add margin for spacing between activities on small screens */
    
  }

  .activity-images {
    display: flex;
    justify-content: center; /* Center images horizontally */
  }

  .activity-images img {
    width: 250px; /* Set a specific width for the circular images */
    height: 250px; /* Set a specific height for the circular images */
    object-fit: cover; /* Ensure images maintain their aspect ratio within the specified dimensions */
    
    margin: 5px; /* Add margin between circular images */
  }
}



