

.gallery-container {
    max-width: 100%;
    margin: 0 auto;
   
    text-align: center;
  }
  
  .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
  }
  
  .gallery-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (max-width: 600px) {
    .modal {
      display: none !important; /* Important to override modal styles */
    }
  }