/* header.css */
/* Header Styles */
header {
  background-color: #394244;
  color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 10vh;
}


nav {
  display: flex;
  align-items: center;
  
}

/* Link Styles */
.nav-link {
  display: inline-block;
  padding: 8px 10px;
  margin: 15px 5px;
  border-radius: 5px;
  color: #394244;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

/* Link Colors (Add your own colors) */
.aboutus {  color: #ffffff }

.aboutus:hover {  color: #5AA9EB }
.activities {color: #ffffff}
.activities:hover {  color: #ED596B }

.staffpage {  color: #ffffff  }
.staffpage:hover {  color: #5BDBB0 }

.gallery {  color: #ffffff  }
.gallery:hover {  color: #5BDBB0 }

.holidays { color: #ffffff }
.holidays:hover {  color: #5AA9EB }

.contact {  color: #ffffff}
.contact:hover {  color: #ED596B }

.signup { color: #ffffff }
.signup:hover {  color: #5BDBB0 }

.donation {  color: #ffffff }
.donation:hover {  color: #5AA9EB }


/* Logo Styles */
.logo {
  width: 80px;
  height: 60px;
  vertical-align: middle;
  margin-right: 10px;
  
}
  .logo23 {
    width: 200px;
    height: 100px;
    
    margin-right:20px;
    
  }
  .logo23 path {
  stroke: black; /* Outline color */
  stroke-width: 1px; /* Thickness of the outline */
  paint-order: stroke fill; /* Ensures stroke is applied first */
}


/* YMCA and YMCA Kindergarten Logo Styles */
.ymca-logo {
  width: 60px;
height: 50px;
vertical-align: middle;
margin-left: 10px;

}

.menu-icon {
  display: none; /* Hide the icon by default for larger screens */
  cursor: pointer;
  margin-left: auto;
  padding: 10px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

@media only screen and (max-width: 768px) {
  /* Display the hamburger icon and hide navigation links for smaller screens */
  .menu-icon {
    display: block;
    margin-left: 50%; 
    padding: 10px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    z-index: 99999;
    background-color: #394244;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-link {
    margin: 0;
    text-align: center;
  }

  /* Adjust font size for navigation links on smaller screens */
  .nav-link {
    font-size: 16px;
  }

  /* Adjust logo sizes for smaller screens and center them */
  .logo {
    width: 80px;
    height: 80px;
    margin: 0 auto; /* Center the logo */
  }
  .logo23 {
    width: 160px;
    height: 80px;
    margin: 0 auto; /* Center the logo */
    margin-left: 20%;
  }

  .ymca-logo {
    width: 40px;
    height: 40px;
    margin: 0 auto; /* Center the logo */
  }
}
